<template>
  <div class="order">
    <div class="webkit-gap"></div>
    <NavHeader
      :title="$t('screens.order.headerTitle')"
      :subtitle="$t('screens.order.headerSubtitle', { tableName })"
      @onBackClick="goBack"
    />
    <div v-if="isLoading" class="py-6 d-flex justify-center align-center">
      <v-progress-circular indeterminate color="black" />
    </div>
    <div v-else style="height: 100%;">
      <LayoutCard
        v-show="totalAmount"
        :loading="isLoading"
        :title="$t('screens.order.summaryTitle')"
        rounded
      >
        <PriceSummaryPayGo
          :subtotal-amount="subtotalAmount"
          :subtotal-without-discount="
            order?.subtotal_before_discounts || order?.subtotal
          "
          :total-amount="totalAmount"
          :transaction-fee="transactionFeeAmount"
          :paid-amount="paidAmount"
          :payments="payments"
          :loyalty-program="loyaltyProgram"
          :loyalty-discount-amount="loyaltyDiscountAmount"
        />
        <v-card
          v-if="!hasUnpaidOrderItems && allItemsPaid"
          color="#489f3133"
          class="d-flex pa-4 mt-6"
          style="border-radius: 20px;"
          flat
        >
          <v-icon color="#489F31" class="mr-4">mdi-check-circle</v-icon>
          <div>
            <span class="tp-text-body green-text">
              {{ $t('screens.payGoOrder.allPaidMessage') }}
            </span>
          </div>
        </v-card>
      </LayoutCard>
      <LayoutCard v-if="!hasOrderItems" grow>
        <StateScreen
          :title="$t('screens.order.emptyStateTitle')"
          :subtitle="$t('screens.order.emptyStateSubtitle')"
          :image="require(`@/assets/images/states/empty.svg`)"
        />
        <div class="mt-auto"></div>
        <div v-if="!hasOrderItems" class="py-4 px-6">
          <Button dark @click="refreshOrder">
            {{ $t('screens.payGoOrder.refreshCta') }}
          </Button>
          <div class="my-3"></div>
          <Button
            v-if="!$store.getters.customizationSelectScreenHideViewMenuButton"
            gray
            @click="goBack"
          >
            {{ $t('screens.payGoOrder.backCta') }}
          </Button>
        </div>
      </LayoutCard>
      <OrderLoyaltyStatusCard
        v-if="hasOrderItems && !allItemsPaid"
        :unique-order-number="uniqueOrderNumber"
        :loyalty-discount-amount="loyaltyDiscountAmount"
        redirect="Order"
      />
      <LayoutCard
        v-if="hasUnpaidOrderItems"
        :loading="isLoading"
        :title="
          hasPaidOrderItems
            ? $t('screens.order.orderListLeftUnpaidProductTitle')
            : $t('screens.order.orderListTitle')
        "
      >
        <div
          v-for="(item, key) in unpaidOrderItems"
          :key="key"
          class="order-item"
          :class="{
            'pb-4': key + 1 < unpaidOrderItems.length,
            'pt-1': key == 0,
          }"
        >
          <OrderItem
            :item-key="key"
            :name="item.name"
            :price="item.price"
            :quantity="item.quantity"
            :modifiers="item.modifiers"
            :is-paid="item.isPaid"
            :selected="item.otherSelected"
            hide-modifiers
            :strikethrough-price="item.strikethroughPrice"
            @click="navigateToItemSelection"
          />
        </div>
      </LayoutCard>
      <LayoutCard
        v-if="hasPaidOrderItems"
        :loading="isLoading"
        :title="$t('screens.order.orderListPaidProductTitle')"
        rounded
      >
        <div
          v-for="(item, key) in paidOrderItems"
          :key="key"
          class="order-item"
          :class="{ 'pb-4': key + 1 < paidOrderItems.length, 'pt-1': key == 0 }"
        >
          <OrderItem
            :item-key="key"
            :name="item.name"
            :price="item.price"
            :quantity="item.quantity"
            :modifiers="item.modifiers"
            :is-paid="item.isPaid"
            :strikethrough-price="item.strikethroughPrice"
            hide-modifiers
          />
        </div>
      </LayoutCard>
      <footer v-if="hasUnpaidOrderItems">
        <Button
          v-if="hasOrderItems"
          :disabled="allItemsPaid || applyingDiscount"
          :loading="sliptButtonLoading"
          data-cy="pay-go-split-cta"
          gray
          @click="navigateToItemSelection"
        >
          <v-icon small color="black" class="mr-1">mdi-call-split</v-icon>
          {{ $t('screens.payGoOrder.payForProductsCta') }}
        </Button>
        <Button
          v-if="hasOrderItems"
          :disabled="allItemsPaid || applyingDiscount"
          :loading="payButtonLoading"
          data-cy="pay-go-pay-all-cta"
          dark
          @click="navigateToPayment"
        >
          <v-icon small color="white" class="mr-1">mdi-arrow-right</v-icon>
          {{ $t('screens.payGoOrder.payAllCta') }}
        </Button>
      </footer>
    </div>
  </div>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import NavHeader from '@/components/Navigation/NavHeader.vue';
import OrderItem from '@/components/Orders/OrderItem.vue';
import StateScreen from '@/components/StateScreen.vue';
import PriceSummaryPayGo from '@/components/common/PriceSummaryPayGo.vue';
import Button from '@/components/Button.vue';
import OrderLoyaltyStatusCard from '@/components/Loyalty/OrderLoyaltyStatusCard.vue';

export default {
  components: {
    LayoutCard,
    NavHeader,
    OrderItem,
    StateScreen,
    Button,
    PriceSummaryPayGo,
    OrderLoyaltyStatusCard,
  },
  data() {
    return {
      isLoading: false,
      uniqueOrderNumber: null,
      sliptButtonLoading: false,
      payButtonLoading: false,
      fetchOrdersInterval: null,
      otherSelectedOptions: [],
    };
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    tableName() {
      return this.$store.state.table.name;
    },
    hasOrderItems() {
      return this.orderItems.length > 0;
    },
    hasPaidOrderItems() {
      return this.orderItems.filter(orderItem => orderItem.isPaid).length > 0;
    },
    hasUnpaidOrderItems() {
      return this.orderItems.filter(orderItem => !orderItem.isPaid).length > 0;
    },
    orderStatusClosed() {
      return this.order?.status === 'closed';
    },
    order() {
      return this.$store.getters.getPOSOrderByUniqueOrderNumber(
        this.uniqueOrderNumber
      );
    },
    orderItems() {
      if (!this.order) {
        return [];
      }
      return (
        this.order.items.map(item => {
          return {
            id: item.id,
            name: item.name,
            quantity: item.quantity,
            modifiers: item.modifiers,
            price: item.price,
            isPaid:
              item.is_paid || this.order.is_paid || this.orderStatusClosed,
            otherSelected: this.otherSelectedOptions.includes(item.id),
            strikethroughPrice: item.strikethrough_price,
          };
        }) || []
      );
    },
    unpaidOrderItems() {
      return this.orderItems.filter(item => !item.isPaid);
    },
    paidOrderItems() {
      return this.orderItems.filter(item => item.isPaid);
    },
    subtotalAmount() {
      return this?.order?.subtotal;
    },
    subtotalWithoutDiscount() {
      return this?.order?.subtotal_before_discounts;
    },
    totalAmount() {
      return this?.order?.total;
    },
    paidAmount() {
      return this.orderStatusClosed
        ? this.subtotalAmount
        : this?.order?.payments_sum;
    },
    transactionFeeAmount() {
      return this?.order?.transaction_fee;
    },
    payments() {
      return this?.order?.payments;
    },
    allItemsPaid() {
      return this.orderItems.every(item => item.isPaid);
    },
    selectedMenuItems() {
      return this.$store.getters.getSelectedMenuItems;
    },
    loyaltyProgramEnabled() {
      return this.$store.getters.functionalityLoyaltyProgramEnabled;
    },
    loyaltyProgram() {
      return this.$store.getters.enrolledRestaurantLoyaltyProgram;
    },
    loyaltyDiscountAmount() {
      return this.subtotalWithoutDiscount - this.subtotalAmount;
    },
    applyingDiscount() {
      return this.$store.getters.getApplyingLoyaltyDiscountToOrder;
    },
  },
  async mounted() {
    this.isLoading = true;

    const sessionToken = this.$route.params.session;
    await this.$store.dispatch('fetchSessionData', { session: sessionToken });
    await this.getOrder();

    this.isLoading = false;

    if (this.uniqueOrderNumber) {
      this.$store.dispatch('deselectAllOrderItems', {
        uniqueOrderNumber: this.uniqueOrderNumber,
      });
    }
    this.$store.dispatch('subscribeToPusherChannels');

    this.fetchPreviouslySelectedOptions();

    window.addEventListener(
      'order-items-selected',
      this.fetchPreviouslySelectedOptions
    );
  },
  beforeDestroy() {
    clearInterval(this.fetchOrdersInterval);

    window.removeEventListener(
      'order-items-selected',
      this.fetchPreviouslySelectedOptions
    );
  },
  methods: {
    async navigateToPayment() {
      this.payButtonLoading = true;
      const [canPay, unpaidItemsSelectedByOthers] = await Promise.all([
        this.checkCanPayForOrder(),
        this.checkIfUnpaidItemsAreSelectedByOthers(),
      ]);

      if (!canPay || unpaidItemsSelectedByOthers) {
        this.payButtonLoading = false;
        return;
      }
      const onSuccess = () => {
        this.$router.push({
          name: 'Pay',
          params: {
            session: this.session,
            order: this.order.unique_order_number,
          },
          query: {
            payForSelectedItems: true,
            ref: 'order',
          },
        });
      };
      await this.$store.dispatch('selectAllUnpaidOrderItems', {
        uniqueOrderNumber: this.uniqueOrderNumber,
        onSuccess,
      });
      this.payButtonLoading = false;
    },
    goBack() {
      this.$router.replace({
        name: 'Select',
        params: { session: this.session },
      });
    },
    navigateToMenu() {
      this.$router.push({
        name: 'Menu',
        params: { session: this.session },
      });
    },
    async checkCanPayForOrder() {
      const canPay = await this.$store.dispatch('checkCanPayForOrder', {
        uniqueOrderNumber: this.uniqueOrderNumber,
      });

      return canPay;
    },
    async checkIfUnpaidItemsAreSelectedByOthers() {
      return await this.$store.dispatch(
        'checkIfUnpaidItemsAreSelectedByOthers',
        {
          uniqueOrderNumber: this.uniqueOrderNumber,
        }
      );
    },
    async getOrder({ force = false } = {}) {
      await this.$store.dispatch('fetchOrdersFromPOS');
      const orderIdFromQuery = this.$route.query.orderNumber;
      const firstOpenOrderId = this.$store.getters.getFirstPOSOpenOrderId;

      if (force) {
        this.uniqueOrderNumber = firstOpenOrderId;
      } else {
        this.uniqueOrderNumber = orderIdFromQuery || firstOpenOrderId;
      }

      if (this.uniqueOrderNumber) {
        this.$router
          .replace({ query: { orderNumber: this.uniqueOrderNumber } })
          .catch(() => {});
      }

      this.initiateFetchOrdersInterval();
    },
    async refreshOrder() {
      this.isLoading = true;
      await this.getOrder({ force: true });
      this.isLoading = false;
    },
    async navigateToItemSelection() {
      this.sliptButtonLoading = true;
      const canPay = await this.checkCanPayForOrder();

      if (!canPay) {
        this.sliptButtonLoading = false;
        return;
      }

      this.$router.push({
        name: 'SelectItems',
        query: { orderNumber: this.uniqueOrderNumber },
      });
      this.sliptButtonLoading = false;
    },
    initiateFetchOrdersInterval() {
      // While user is in pay&go order page, we should repeatedly fetch order (for updates)
      // each 15 seconds.
      if (this.hasOrderItems && this.fetchOrdersInterval === null && !this.orderStatusClosed) {
        this.fetchOrdersInterval = setInterval(() => {
          if (this.applyingDiscount) {
            return;
          }
          this.$store.dispatch('fetchOrdersFromPOS');
        }, 15000);
      }
    },
    async fetchPreviouslySelectedOptions() {
      if (!this.uniqueOrderNumber) {
        return;
      }

      const uniqueOrderNumber = this.uniqueOrderNumber;
      const selectedOrderItems = await this.$store.dispatch(
        'fetchSelectedOrderItems',
        {
          uniqueOrderNumber,
        }
      );
      this.otherSelectedOptions = selectedOrderItems?.others_selected;
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  background-color: $color-background-gray !important;
  display: flex;
  flex-flow: column;
  min-height: 100%;
  padding-bottom: 100px;
}

footer {
  position: fixed;
  width: 100%;
  max-width: $max-app-width;
  display: flex;
  gap: 1em;
  justify-content: space-between;
  padding: 2em 1em calc(2em + env(safe-area-inset-bottom)) 1em;
  bottom: 0px;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(0, 212, 255, 0) 100%
  );
}

.order-item {
  display: flex;
  justify-content: space-between;
}

.green-text {
  color: #0e3d02;
}
</style>
